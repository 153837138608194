import React, { useState, useEffect } from "react";
import Banner from "./Banner";
import TerminalOutput from "./TerminalOutput";
import InputArea from "./InputArea";
import ErrorMessage from "./ErrorMessage";
import WelcomeMessage from "./WelcomeMessage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

// Just a little helper function so I don't have to continually update my age
const getAge = (birthDate: Date) => {
  var today = new Date();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const downloadFile = (uri: string, downloadName: string) => {
  const link = document.createElement("a");
  link.download = downloadName;
  link.href = uri;
  link.click();
  link.remove();
};

type TerminalProps = {
  terminalPrompt?: string;
  banner?: string;
  welcomeMessage?: string;
};
const Terminal = (props: TerminalProps) => {
  const { terminalPrompt = ">", banner, welcomeMessage } = props;
  const [output, setOutput] = useState<(string | JSX.Element)[]>([]);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState(3);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const scrollLastCommandTop = () => {
    scrollRef.current?.scrollIntoView();
  };

  useEffect(scrollLastCommandTop, [output]);

  const echoCommands = [
    "menu",
    "about",
    "contact",
  //  "blogposts",
  //   "blogcategories",

  ] as const;
  type EchoCommand = typeof echoCommands[number];
  const utilityCommands = ["clear", "all", ] as const;
  type UtilityCommand = typeof utilityCommands[number];
  const allCommands = [...echoCommands, ...utilityCommands] as const;
  type Command = typeof allCommands[number];

  function isEchoCommand(arg: string): arg is EchoCommand {
    return (echoCommands as ReadonlyArray<string>).includes(arg);
  }

  function isUtilityCommand(arg: string): arg is UtilityCommand {
    return (utilityCommands as ReadonlyArray<string>).includes(arg);
  }

  function isValidCommand(arg: string): arg is Command {
    return isEchoCommand(arg) || isUtilityCommand(arg);
  }

  const glow = (text: string) => {
    return <span className="terminal-glow">{text}</span>;
  };

  const commands: { [key in EchoCommand]: JSX.Element } = {
    menu: (
      <div>
        <p>
        Just type any of the commands below and press Enter to get some more info. 
        You can even type a few letters and press [tab] or '.' to autocomplete.
        </p>
        <dt>about</dt> <br />
          <dd>About Kieram</dd> <br />

          {/* <dt>blogposts</dt> <br />

          <dd>Blog Posts</dd> <br />

          <dt>blogcategories</dt> <br />

          <dd>Blog Categories</dd> <br /> */}

          <dt>contact</dt> <br />

          <dd>Get In Touch</dd>
        
      </div>
    ),
    about: (
      <div>
<dt>
Follow me...

If you wanna live
</dt>
<br />
<dd>

Kieram is the most watched first aid instructor on the internet, with over <strong>1,800,000,000</strong>  views across social media.
<br /><br />
From nuclear fallout and human stampedes to slit throats and papercuts he's gonna keep you alive.
</dd><br /><br />

        
  <dt>Past</dt> <br />
  <dd>On May 15, 1984, Kieram was born in Vancouver, Canada. He spent his childhood traveling back and forth between
    Canada and The United States, eventually immigrating and taking residence in Florida in his teens. In 2002 he
    graduated from High School and spent the next two years pursuing his dream to become a rock star. In 2004 he started
    an apprenticeship in website design and digital marketing, which lasted two years. Upon completing his
    apprenticeship he started freelancing and building websites for local small businesses. <br />
    <br />
    While this work paid well it did not leave him fulfilled. He wanted to create impact, and do something to help the
    world. Fast forward 6 years and on March 24, 2010, the Great Tohoku Earthquake & Tsunami struck Japan. As a lover of
    Japanese culture, it was this catalyst that got Kieram to do his first disaster relief volunteer work.
    <br /><br />
    He was hooked, after that life-changing experience helping people in Japan, he started volunteering at more
    disasters. By 2012 he wanted to take things to the next level, and make his own rescue team, so he put himself
    through EMT school and then Advanced EMT school by 2014. Invigorated with his training he started his own non-profit
    and started teaching first aid, and disaster preparedness to the general public throughout the world, additionally
    he formed his natural disater rescue team he operated from 2016 until 2020.
    <br /><br />
    The 2020 Pandemic forced Kieram to move all of his trainings online. It wasn't until November 2021 did Kieram begin
    making his now famous TikToks and Instagram Reels, where he teaches viewers CPR and how to save lives.
    <br /><br />
    After nearly two years of laboring, in 2022, he published his first book: Instant Hero - a simple guide for learning
    how to save lives. The book was designed to give life-saving information in an easy-to-understand way so that even a
    child could read it and use it. Which is available to download for free in his Masters of Disasters community on
    locals.com </dd> <br /><br />

    <dt>Present</dt> <br />
    <dd>
    Kieram or “Survival Daddy” as he is called online, spends his days volunteering, creating educational materials on
    topics ranging from CPR to disaster preparedness, shooting short-form videos (TikToks, Reels, Shorts), and shooting
    long-form video for future courses.<br /><br />

    For fun, he likes to study videography and cinematography, practice piano, and learn German and Spanish on Duolingo.
    <br /><br />
    In addition to the above he is working on writing his next book which will be about drug abuse and it's impact on
    mankind's well being.
    </dd> <br /> <br />



    <dt>Future</dt> <br />



    <dd>
    At the time of this writing, Kieram has over 5 million followers across social media, making him the most followed first
aid instructor in the world.
<br /> <br />
Kieram has a lot of very big and very ambitious goals for the future. But, he insists on keeping them secret until they
are achieved and ready to be unveiled to the world. But he will say this: “Social media shorts is just the beginning”.
    </dd>
    <br /> <br />



<dt>Teacher</dt> <br />

<dd>
Kieram is one of the most popular first aid instructors on the planet, with over 1,800,000,000 views accross social
media. Teaching people who to survive from everything from nuclear fallout, scammers in NYC and gunshot wounds to the
chest.</dd>

<br /> <br />



<dt>Author</dt> <br />

<dd>
Author of Instant Hero. A Simple Guide for Learning How to Save Lives & Confidently Handle Any Medical Emergency
</dd>

<br /> <br />



<dt>Volunteer</dt> <br />
<dd>
Kieram has been working to spread the truth about drugs for 10 years
</dd>


<br /> <br />



<dt>Activist</dt> <br />
<dd>
Kieram has volunteered thousands of hours at over 8 disasters world wide, from hurricanes in Japan to Tornados in Alabama.
</dd>

</div>
    ),




    // blogposts: (
    //   <>
      
    //   </>
    // ),
    // blogcategories: (
    //   <>
        
    //   </>
    // ),


    contact: (
  
        <div>
        
            <dt>Instagram</dt>
            <p><a href="https://www.instagram.com/kieram.litchfield/">https://www.instagram.com/kieram.litchfield/</a>
            </p><br />
            <dt>TikTok</dt>
            <p><a href="https://www.tiktok.com/@kieram.litchfield">https://www.tiktok.com/@kieram.litchfield</a></p><br />
            <dt>YouTube</dt>
            <p><a href="https://www.youtube.com/KieramLitchfield">https://www.youtube.com/KieramLitchfield</a></p><br />
            <dt>Twitter</dt>
            <p><a href="https://twitter.com/KieramLitch">https://twitter.com/KieramLitch</a></p>
          
        </div>     
    ),

  
    
  };

  const processCommand = (input: string) => {
    logEvent(analytics, "command_received", { command: input });

    // Store a record of this command with a ref to allow us to scroll it into view.
    // Note: We use a ref callback here because setting the ref directly, then clearing output seems to set the ref to null.
    const commandRecord = (
      <div
        ref={(el) => (scrollRef.current = el)}
        className="terminal-command-record"
      >
        <span className="terminal-prompt">{terminalPrompt}</span>{" "}
        <span>{input}</span>
      </div>
    );

    // Add command to to history if the command is not empty
    if (input.trim()) {
      setHistory([...history, input]);
      setHistoryIndex(history.length + 1);
    }

    // Now process command, ignoring case
    const inputCommand = input.toLowerCase();
    if (!isValidCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">
          <ErrorMessage command={inputCommand} />
        </div>,
      ]);
    } else if (isEchoCommand(inputCommand)) {
      setOutput([
        ...output,
        commandRecord,
        <div className="terminal-command-output">{commands[inputCommand]}</div>,
      ]);
    } else if (isUtilityCommand(inputCommand)) {
      switch (inputCommand) {
        case "clear": {
          setOutput([]);
          break;
        }
        case "all": {
          // Output all commands in a custom order.
          const allCommandsOutput = [
            "about",
    
            "contact",
        
          ].map((command) => (
            <>
              <div className="terminal-heading">{command}</div>
              <div className="terminal-command-output">
                {commands[command as EchoCommand]}
              </div>
            </>
          ));

          setOutput([commandRecord, ...allCommandsOutput]);
          break;
        }
       
      }
    }
  };

  const getHistory = (direction: "up" | "down") => {
    let updatedIndex;
    if (direction === "up") {
      updatedIndex = historyIndex === 0 ? 0 : historyIndex - 1;
    } else {
      updatedIndex =
        historyIndex === history.length ? history.length : historyIndex + 1;
    }
    setHistoryIndex(updatedIndex);
    return updatedIndex === history.length ? "" : history[updatedIndex];
  };

  const getAutocomplete = (input: string) => {
    const matchingCommands = allCommands.filter((c) => c.startsWith(input));
    if (matchingCommands.length === 1) {
      return matchingCommands[0];
    } else {
      const commandRecord = (
        <div
          ref={(el) => (scrollRef.current = el)}
          className="terminal-command-record"
        >
          <span className="terminal-prompt">{terminalPrompt}</span>{" "}
          <span>{input}</span>
        </div>
      );
      setOutput([...output, commandRecord, matchingCommands.join("    ")]);
      return input;
    }
  };

  const focusOnInput = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Tab") {
      // Prevent tab from moving focus
      event.preventDefault();
    }
    inputRef.current?.focus();
  };

  return (
    <div className="terminal-container" tabIndex={-1} onKeyDown={focusOnInput}>
      <div className="terminal-content">
        {banner && <Banner banner={banner} />}
        {welcomeMessage && (
          <WelcomeMessage message={welcomeMessage} inputRef={inputRef} />
        )}
        <TerminalOutput outputs={output} />
        <InputArea
          setOutput={setOutput}
          processCommand={processCommand}
          getHistory={getHistory}
          getAutocomplete={getAutocomplete}
          inputRef={inputRef}
          terminalPrompt={terminalPrompt}
        />
      </div>
    </div>
  );
};

export default Terminal;

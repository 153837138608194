import React, { useEffect } from "react";

type WelcomeMessageProps = {
  message: string;
  inputRef: React.RefObject<HTMLInputElement>;
};

const WelcomeMessage = (props: WelcomeMessageProps) => {
  const welcomeMessageRef = React.useRef<HTMLDivElement>(null);
  const indexRef = React.useRef(0); // Use ref to track the index without triggering re-renders

  useEffect(() => {
    // Disable the input initially
    if (props.inputRef.current) {
      props.inputRef.current.disabled = true;
    }

    // Only start typing if the message and index are valid
    if (props.message) {
      const typeText = setInterval(() => {
        if (welcomeMessageRef.current && indexRef.current < props.message.length) {
          welcomeMessageRef.current.insertAdjacentText(
            "beforeend",
            props.message.charAt(indexRef.current) // Insert one character at a time
          );
          indexRef.current++; // Move to the next character
        } else {
          clearInterval(typeText); // Clear the interval once complete
          if (props.inputRef.current) {
            props.inputRef.current.disabled = false; // Re-enable the input
            props.inputRef.current.focus(); // Focus on the input
          }
        }
      }, 30); // Adjust typing speed here

      // Cleanup the interval on component unmount or when message changes
      return () => clearInterval(typeText);
    }
  }, [props.inputRef, props.message]); // Only depend on inputRef and message (no dependency on index)

  return <div ref={welcomeMessageRef} className="terminal-welcome-message"></div>;
};

export default WelcomeMessage;

import React from "react";
import "./App.css";
import Terminal from "./components/Terminal";

const getYear = () => {
  return new Date().getFullYear();
};

const welcomeMessage = `Welcome to my site!
Type 'menu' and press Enter to view a list of available commands.
`;

const bannerCondensed =


"    __ __ ______________  ___    __  ___ \n" +
"   / //_//  _/ ____/ __ \\/   |  /  |/  / \n" +
"  / ,<   / // __/ / /_/ / /| | / /|_/ /  \n" +
" / /| |_/ // /___/ _, _/ ___ |/ /  / /   \n" +
"/_/ |_/___/_____/_/ |_/_/  |_/_/  /_/    \n" +
                                     
  "kieram.com \u00A9 " + getYear();

const prompt = ">";

function App() {
  return (
    <Terminal
      welcomeMessage={welcomeMessage}
      banner={bannerCondensed}
      terminalPrompt={prompt}
    />
  );
}

export default App;




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyA6_p6oSGt17TUcKcEJ0v4gkMEiskSgoHc",
  authDomain: "kieram-terminal.firebaseapp.com",
  projectId: "kieram-terminal",
  storageBucket: "kieram-terminal.firebasestorage.app",
  messagingSenderId: "5334257790",
  appId: "1:5334257790:web:8c3fb6dcfff88a357c082f",
  measurementId: "G-VVFEBQQDVZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };

